/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './themes/styles.scss';
import App from './App';

import storeConfig from './store/storeConfig';

//import { PublicClientApplication } from "@azure/msal-browser";
//import { MsalProvider } from "@azure/msal-react";
import { msalConfig, authProvider  } from "./authConfig";
import { AzureAD } from 'react-aad-msal';

const store = storeConfig();
//const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  // <MsalProvider instance={msalInstance}>
    //<AzureAD provider={authProvider}>
    <Provider store={store}>
      <App />
    </Provider>,
    //</AzureAD>,
  //</MsalProvider>,
  document.getElementById('root')
);
