/* eslint-disable */

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  // faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faUserCheck,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { authProvider } from "../../authConfig";
import {
  AzureAD,
  AuthenticationState,
  accountInfo,
 } from 'react-aad-msal';

import { Context } from 'context/AuthContext';
import Brand from 'components/_base/Brand';

import './styles.scss';

const NavigationMenu = () => {
  const { handleLogout, handleSSOLogout } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_brf'));

  return (
    <div className="menu">
      <div className="header-menu">
        <div>
          <Brand white />
          <p className="mt-3">Bem Vindo</p>
          <h3>{userAuth && userAuth.nome}</h3>
        </div>
      </div>
      <div className="body-menu">
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/pedido-materiais">
              <FontAwesomeIcon icon={faFile} />
              <span>Solicitar Artes Personalizadas</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/pedido-campanha">
              <FontAwesomeIcon icon={faFile} />
              <span>Solicitar Campanhas Sazonais</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/artes-facil">
              <FontAwesomeIcon icon={faFile} />
              <span>Solicitar Artes fácil</span>
            </Link>
          </li> */}
          <li>
            <Link to="/rascunho">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Rascunhos</span>
            </Link>
          </li>
          <li>
            <Link to="/historico-pedidos">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Histórico de Pedidos</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/on-off-relatorios">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Plano mídias ON/OFF</span>
            </Link>
          </li> */}
          <li>
            <Link to="/pendencias">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>Aprovações e Evidências</span>
            </Link>
          </li>
          {userAuth?.aprovaUsuario && (
            <li>
              <Link to="/usuario-aprovacao">
                <FontAwesomeIcon icon={faUserCheck} />
                <span>Aprovar Usuários</span>
              </Link>
            </li>
          )}
          <li>
            <Link to="/consulta">
              <FontAwesomeIcon icon={faSearch} />
              <span>Consulta</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/banco-de-imagens">
              <FontAwesomeIcon icon={faImage} />
              <span>Banco de Imagens</span>
            </Link>
          </li> */}
          <li>
            <Link to="/banco-de-artes">
              <FontAwesomeIcon icon={faFileImage} />
              <span>Banco de Artes</span>
            </Link>
          </li>
          <li>
            <Link to="/relatorios">
              <FontAwesomeIcon icon={faFileExcel} />
              <span>Relatórios</span>
            </Link>
          </li>
          <li>
            <Link to="/atendimento">
              <FontAwesomeIcon icon={faAt} />
              <span>Atendimento</span>
            </Link>
          </li>
          <li>
            <Link to="/meus-dados">
              <FontAwesomeIcon icon={faUser} />
              <span>Meus Dados</span>
            </Link>
          </li>
        </ul>
        <AzureAD provider={authProvider}>
          {
            ({ login, logout, authenticationState, error, accountInfo }) => {
              switch (authenticationState) {
                case AuthenticationState.Authenticated:
                  return (
                    <div className="logout-button">
                      <Button variant="primary" onClick={()=>{handleSSOLogout(logout)}}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <span>Sair</span>
                      </Button>
                    </div>
                  );
                case AuthenticationState.Unauthenticated:
                  return (
                    <div className="logout-button">
                      <Button variant="primary" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <span>Sair</span>
                      </Button>
                    </div>
                  );
                case AuthenticationState.InProgress:
                  return (<p>Authenticating...</p>);
              }
            }
          }
        </AzureAD>
      </div>
    </div>
  );
};

export default NavigationMenu;
